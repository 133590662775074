.aplayer {
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  margin: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: initial;
  position: relative; }
  .aplayer * {
    box-sizing: content-box; }
  .aplayer svg {
    width: 100%;
    height: 100%; }
    .aplayer svg path,
    .aplayer svg circle {
      fill: #fff; }
  .aplayer.aplayer-withlist .aplayer-info {
    border-bottom: 1px solid #e9e9e9; }
  .aplayer.aplayer-withlist .aplayer-list {
    display: block; }
  .aplayer.aplayer-withlist .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-menu {
    display: inline; }
  .aplayer.aplayer-withlist .aplayer-icon-order {
    display: inline; }
  .aplayer.aplayer-withlrc .aplayer-pic {
    height: 90px;
    width: 90px; }
  .aplayer.aplayer-withlrc .aplayer-info {
    margin-left: 90px;
    height: 90px;
    padding: 10px 7px 0 7px; }
  .aplayer.aplayer-withlrc .aplayer-lrc {
    display: block; }
  .aplayer.aplayer-narrow {
    width: 66px; }
    .aplayer.aplayer-narrow .aplayer-info {
      display: none; }
    .aplayer.aplayer-narrow .aplayer-list {
      display: none; }
    .aplayer.aplayer-narrow .aplayer-pic,
    .aplayer.aplayer-narrow .aplayer-body {
      height: 66px;
      width: 66px; }
  .aplayer.aplayer-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 99;
    overflow: visible;
    max-width: 400px;
    box-shadow: none; }
    .aplayer.aplayer-fixed .aplayer-list {
      margin-bottom: 65px;
      border: 1px solid #eee;
      border-bottom: none; }
    .aplayer.aplayer-fixed .aplayer-body {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      z-index: 99;
      background: #fff;
      padding-right: 18px;
      transition: all 0.3s ease;
      max-width: 400px; }
    .aplayer.aplayer-fixed .aplayer-lrc {
      display: block;
      position: fixed;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0;
      z-index: 98;
      pointer-events: none;
      text-shadow: -1px -1px 0 #fff; }
      .aplayer.aplayer-fixed .aplayer-lrc:before, .aplayer.aplayer-fixed .aplayer-lrc:after {
        display: none; }
    .aplayer.aplayer-fixed .aplayer-info {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      transition: all 0.3s ease;
      border-bottom: none;
      border-top: 1px solid #e9e9e9; }
      .aplayer.aplayer-fixed .aplayer-info .aplayer-music {
        width: calc(100% - 105px); }
    .aplayer.aplayer-fixed .aplayer-miniswitcher {
      display: block; }
    .aplayer.aplayer-fixed.aplayer-narrow .aplayer-info {
      display: block;
      -webkit-transform: scaleX(0);
              transform: scaleX(0); }
    .aplayer.aplayer-fixed.aplayer-narrow .aplayer-body {
      width: 66px !important; }
    .aplayer.aplayer-fixed.aplayer-narrow .aplayer-miniswitcher .aplayer-icon {
      -webkit-transform: rotateY(0);
              transform: rotateY(0); }
    .aplayer.aplayer-fixed .aplayer-icon-back,
    .aplayer.aplayer-fixed .aplayer-icon-play,
    .aplayer.aplayer-fixed .aplayer-icon-forward,
    .aplayer.aplayer-fixed .aplayer-icon-lrc {
      display: inline-block; }
    .aplayer.aplayer-fixed .aplayer-icon-back,
    .aplayer.aplayer-fixed .aplayer-icon-play,
    .aplayer.aplayer-fixed .aplayer-icon-forward,
    .aplayer.aplayer-fixed .aplayer-icon-menu {
      position: absolute;
      bottom: 27px;
      width: 20px;
      height: 20px; }
    .aplayer.aplayer-fixed .aplayer-icon-back {
      right: 75px; }
    .aplayer.aplayer-fixed .aplayer-icon-play {
      right: 50px; }
    .aplayer.aplayer-fixed .aplayer-icon-forward {
      right: 25px; }
    .aplayer.aplayer-fixed .aplayer-icon-menu {
      right: 0; }
  .aplayer.aplayer-mobile .aplayer-icon-volume-down {
    display: none; }
  .aplayer.aplayer-arrow .aplayer-icon-order,
  .aplayer.aplayer-arrow .aplayer-icon-loop {
    display: none; }
  .aplayer.aplayer-loading .aplayer-info .aplayer-controller .aplayer-loading-icon {
    display: block; }
  .aplayer.aplayer-loading .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-played .aplayer-thumb {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .aplayer .aplayer-body {
    position: relative; }
  .aplayer .aplayer-icon {
    width: 15px;
    height: 15px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    opacity: .8;
    vertical-align: middle;
    padding: 0;
    font-size: 12px;
    margin: 0;
    display: inline-block; }
    .aplayer .aplayer-icon path {
      transition: all .2s ease-in-out; }
  .aplayer .aplayer-icon-order,
  .aplayer .aplayer-icon-back,
  .aplayer .aplayer-icon-play,
  .aplayer .aplayer-icon-forward,
  .aplayer .aplayer-icon-lrc {
    display: none; }
  .aplayer .aplayer-icon-lrc-inactivity svg {
    opacity: 0.4; }
  .aplayer .aplayer-icon-forward {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .aplayer .aplayer-lrc-content {
    display: none; }
  .aplayer .aplayer-pic {
    position: relative;
    float: left;
    height: 66px;
    width: 66px;
    background-size: cover;
    background-position: center;
    transition: all 0.3s ease;
    cursor: pointer; }
    .aplayer .aplayer-pic:hover .aplayer-button {
      opacity: 1; }
    .aplayer .aplayer-pic .aplayer-button {
      position: absolute;
      border-radius: 50%;
      opacity: 0.8;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      transition: all 0.1s ease; }
      .aplayer .aplayer-pic .aplayer-button path {
        fill: #fff; }
    .aplayer .aplayer-pic .aplayer-hide {
      display: none; }
    .aplayer .aplayer-pic .aplayer-play {
      width: 26px;
      height: 26px;
      border: 2px solid #fff;
      bottom: 50%;
      right: 50%;
      margin: 0 -15px -15px 0; }
      .aplayer .aplayer-pic .aplayer-play svg {
        position: absolute;
        top: 3px;
        left: 4px;
        height: 20px;
        width: 20px; }
    .aplayer .aplayer-pic .aplayer-pause {
      width: 16px;
      height: 16px;
      border: 2px solid #fff;
      bottom: 4px;
      right: 4px; }
      .aplayer .aplayer-pic .aplayer-pause svg {
        position: absolute;
        top: 2px;
        left: 2px;
        height: 12px;
        width: 12px; }
  .aplayer .aplayer-info {
    margin-left: 66px;
    padding: 14px 7px 0 10px;
    height: 66px;
    box-sizing: border-box; }
    .aplayer .aplayer-info .aplayer-music {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0 0 13px 5px;
      -webkit-user-select: text;
         -moz-user-select: text;
          -ms-user-select: text;
              user-select: text;
      cursor: default;
      padding-bottom: 2px;
      height: 20px; }
      .aplayer .aplayer-info .aplayer-music .aplayer-title {
        font-size: 14px; }
      .aplayer .aplayer-info .aplayer-music .aplayer-author {
        font-size: 12px;
        color: #666; }
    .aplayer .aplayer-info .aplayer-controller {
      position: relative;
      display: flex; }
      .aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap {
        margin: 0 0 0 5px;
        padding: 4px 0;
        cursor: pointer !important;
        flex: 1; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap:hover .aplayer-bar .aplayer-played .aplayer-thumb {
          -webkit-transform: scale(1);
                  transform: scale(1); }
        .aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar {
          position: relative;
          height: 2px;
          width: 100%;
          background: #cdcdcd; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-loaded {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background: #aaa;
            height: 2px;
            transition: all 0.5s ease; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-played {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            height: 2px; }
            .aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-played .aplayer-thumb {
              position: absolute;
              top: 0;
              right: 5px;
              margin-top: -4px;
              margin-right: -10px;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              cursor: pointer;
              transition: all .3s ease-in-out;
              -webkit-transform: scale(0);
                      transform: scale(0); }
      .aplayer .aplayer-info .aplayer-controller .aplayer-time {
        position: relative;
        right: 0;
        bottom: 4px;
        height: 17px;
        color: #999;
        font-size: 11px;
        padding-left: 7px; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-time-inner {
          vertical-align: middle; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon {
          cursor: pointer;
          transition: all 0.2s ease; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon path {
            fill: #666; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-loop {
            margin-right: 2px; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon:hover path {
            fill: #000; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-menu {
            display: none; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-time.aplayer-time-narrow .aplayer-icon-mode {
          display: none; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-time.aplayer-time-narrow .aplayer-icon-menu {
          display: none; }
      .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap {
        position: relative;
        display: inline-block;
        margin-left: 3px;
        cursor: pointer !important; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap:hover .aplayer-volume-bar-wrap {
          height: 40px; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap {
          position: absolute;
          bottom: 15px;
          right: -3px;
          width: 25px;
          height: 0;
          z-index: 99;
          overflow: hidden;
          transition: all .2s ease-in-out; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap.aplayer-volume-bar-wrap-active {
            height: 40px; }
          .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap .aplayer-volume-bar {
            position: absolute;
            bottom: 0;
            right: 10px;
            width: 5px;
            height: 35px;
            background: #aaa;
            border-radius: 2.5px;
            overflow: hidden; }
            .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap .aplayer-volume-bar .aplayer-volume {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 5px;
              transition: all 0.1s ease; }
      .aplayer .aplayer-info .aplayer-controller .aplayer-loading-icon {
        display: none; }
        .aplayer .aplayer-info .aplayer-controller .aplayer-loading-icon svg {
          position: absolute;
          -webkit-animation: rotate 1s linear infinite;
                  animation: rotate 1s linear infinite; }
  .aplayer .aplayer-lrc {
    display: none;
    position: relative;
    height: 30px;
    text-align: center;
    overflow: hidden;
    margin: -10px 0 7px; }
    .aplayer .aplayer-lrc:before {
      position: absolute;
      top: 0;
      z-index: 1;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 10%;
      content: ' ';
      background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); }
    .aplayer .aplayer-lrc:after {
      position: absolute;
      bottom: 0;
      z-index: 1;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 33%;
      content: ' ';
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ccffffff',GradientType=0 ); }
    .aplayer .aplayer-lrc p {
      font-size: 12px;
      color: #666;
      line-height: 16px !important;
      height: 16px !important;
      padding: 0 !important;
      margin: 0 !important;
      transition: all 0.5s ease-out;
      opacity: 0.4;
      overflow: hidden; }
      .aplayer .aplayer-lrc p.aplayer-lrc-current {
        opacity: 1;
        overflow: visible;
        height: initial !important;
        min-height: 16px; }
    .aplayer .aplayer-lrc.aplayer-lrc-hide {
      display: none; }
    .aplayer .aplayer-lrc .aplayer-lrc-contents {
      width: 100%;
      transition: all 0.5s ease-out;
      -webkit-user-select: text;
         -moz-user-select: text;
          -ms-user-select: text;
              user-select: text;
      cursor: default; }
  .aplayer .aplayer-list {
    overflow: auto;
    transition: all 0.5s ease;
    will-change: height;
    display: none;
    overflow: hidden; }
    .aplayer .aplayer-list.aplayer-list-hide {
      max-height: 0 !important; }
    .aplayer .aplayer-list ol {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow-y: auto; }
      .aplayer .aplayer-list ol::-webkit-scrollbar {
        width: 5px; }
      .aplayer .aplayer-list ol::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #eee; }
      .aplayer .aplayer-list ol::-webkit-scrollbar-thumb:hover {
        background-color: #ccc; }
      .aplayer .aplayer-list ol li {
        position: relative;
        height: 32px;
        line-height: 32px;
        padding: 0 15px;
        font-size: 12px;
        border-top: 1px solid #e9e9e9;
        cursor: pointer;
        transition: all 0.2s ease;
        overflow: hidden;
        margin: 0; }
        .aplayer .aplayer-list ol li:first-child {
          border-top: none; }
        .aplayer .aplayer-list ol li:hover {
          background: #efefef; }
        .aplayer .aplayer-list ol li.aplayer-list-light {
          background: #e9e9e9; }
          .aplayer .aplayer-list ol li.aplayer-list-light .aplayer-list-cur {
            display: inline-block; }
        .aplayer .aplayer-list ol li .aplayer-list-cur {
          display: none;
          width: 3px;
          height: 22px;
          position: absolute;
          left: 0;
          top: 5px;
          cursor: pointer; }
        .aplayer .aplayer-list ol li .aplayer-list-index {
          color: #666;
          margin-right: 12px;
          cursor: pointer; }
        .aplayer .aplayer-list ol li .aplayer-list-author {
          color: #666;
          float: right;
          cursor: pointer; }
  .aplayer .aplayer-notice {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 10px;
    transition: all .3s ease-in-out;
    overflow: hidden;
    color: #fff;
    pointer-events: none;
    background-color: #f4f4f5;
    color: #909399; }
  .aplayer .aplayer-miniswitcher {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: #e6e6e6;
    width: 18px;
    border-radius: 0 2px 2px 0; }
    .aplayer .aplayer-miniswitcher .aplayer-icon {
      height: 100%;
      width: 100%;
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
      transition: all 0.3s ease; }
      .aplayer .aplayer-miniswitcher .aplayer-icon path {
        fill: #666; }
      .aplayer .aplayer-miniswitcher .aplayer-icon:hover path {
        fill: #000; }

@-webkit-keyframes aplayer-roll {
  0% {
    left: 0; }
  100% {
    left: -100%; } }

@keyframes aplayer-roll {
  0% {
    left: 0; }
  100% {
    left: -100%; } }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
